const app = angular.module('cutlist', ['picardy.fontawesome', 'pascalprecht.translate', 'ngCookies', 'ui.bootstrap', 'ngHandsontable', 'ngAnimate']);

app.config(['$httpProvider', '$translateProvider', function ($httpProvider, $translateProvider) {
    $httpProvider.interceptors.push('requestInterceptor');

    $translateProvider.useStaticFilesLoader({
        prefix: '../data/locales/',
        suffix: '.json'
    });

    $translateProvider.preferredLanguage('bg');
    $translateProvider.useCookieStorage();
}]);

app.run(['$rootScope', '$window', function ($rootScope, $window) {
    let assetsLoaded = false;
    showOverlay();

    $rootScope.$on('loading:complete', () => {
        assetsLoaded = true;
        if (!$rootScope.processing) {
            hideOverlay();
        }
    });

    $rootScope.$watch('processing', () => {
        if (assetsLoaded && !$rootScope.processing) {
            hideOverlay();
        } else {
            showOverlay();
        }
    });

    function showOverlay () {
        $window.document.body.style.visibility = 'visible';
        $window.document.querySelector('.master-row').style.display = 'none';
        $window.document.querySelector('#overlay').style.display = 'block';
    }

    function hideOverlay () {
        $window.document.querySelector('.master-row').style.display = 'block';
        $window.document.querySelector('#overlay').style.display = 'none';
    }

    $rootScope.openSettings = function () {
        window.location.href = '/settings';
    };

    $rootScope.back = function (ev, path) {
        ev.preventDefault();
        $window.open(path || '/', '_self');
    };
}]);
